import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../parts/Footer"
import Header from "../parts/Header"
import { graphql } from "gatsby"
import CustomHelmet from "../components/seo/CustomHelmet"
import ArticleCard from "../components/blog/ArticleCard";

const Blog = ({ location, data }) => {
  const canonicalUrl = data.site.siteMetadata.siteUrl + location.pathname

  const { allMarkdownRemark } = data;
  const { nodes } = allMarkdownRemark;
  const articles = nodes;
  return (
    <>
      <CustomHelmet title={"Blog"} canonicalUrl={canonicalUrl} />
      <Header activeKey="/blog" />
      <main className="py-5" style={{ marginTop: '81px' }}>
        <Container>
          <h1>Blog</h1>
          <p className="mb-4">Read our blog posts to keep up to date with our research and everything happening at Vertaix.</p>
          <Row className="gy-4" >
            {articles.map(article => <Col key={article.id} sm={12} md={6} lg={4}>
              <ArticleCard 
                title={article.frontmatter.title}
                excerpt={article.frontmatter.excerpt}
                thumbnail={article.frontmatter.thumbnail.childImageSharp.fluid.src}
                content={article.frontmatter.content}
                slug={article.frontmatter.slug}
                category={article.frontmatter.category}
                publicationDate={article.frontmatter.publicationDate}
                external={article.frontmatter.external}
                author={article.frontmatter.author}
              />
            </Col>)}
          </Row>


        </Container>
      </main>
      <Footer />
    </>
  )
}

export const pageQuery = graphql`
{
      site {
        siteMetadata {
          siteUrl
        }
      },
      allMarkdownRemark(
        filter: {frontmatter: {taxonomy: {eq: "blog"}}}
        sort: {order: DESC, fields: frontmatter___publicationDate}
      ) {
        nodes {
          frontmatter {
            title
            thumbnail {
              id
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            author
            slug
            excerpt
            category
            external
            publicationDate(formatString: "MMM DD/YYYY")
          }
          id
        }
      }
  }
`


export default Blog;
