import { Link } from "gatsby";
import * as React from "react"
import { FaExternalLinkAlt } from "react-icons/fa";

// markup
const ArticleCard = ({ title, thumbnail, slug, category, publicationDate, external, author }) => {
    return (
        <Link className="text-decoration-none" to={slug} target={external ? "_blank" : ""}>
            <div className="card h-100 w-100 overflow-hidden">

                <div className="bg-dark" style={{
                    height: '300px',
                    width: 'auto',
                    backgroundImage: `url(${thumbnail})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                }}>
                    <span className="position-absolute badge rounded-pill bg-light text-primary text-uppercase fs-5" style={{
                        left: '24px',
                        bottom: '18px'
                    }}>{category}</span>

                    {
                        external ?
                            <span className="position-absolute badge rounded-pill bg-light text-black fw-normal fs-5" style={{
                                right: '24px',
                                bottom: '18px',
                                opacity: .90
                            }}>From: {author} <FaExternalLinkAlt size={12} />
                            </span>
                            :

                            ""
                    }

                </div>
                <div className="card-body p-3">
                    <p className="text-primary text-small fs-4 m-1">{publicationDate}</p>
                    <h2 className="card-title mb-3 text-uppercase fw-normal text-black" >{title}</h2>
                </div>
            </div>
        </Link>
    )
}

export default ArticleCard;
